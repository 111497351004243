import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { styled } from 'twin.macro'
import Headroom from 'react-headroom'

import ThemeToggle from './theme-toggle'

const Container = styled.header`
  ${tw`dark:bg-night-sky`};

  .headroom--pinned {
    ${tw`dark:bg-night-sky`}

    ${tw`dark:!shadow-navDark`};
  }
`
const HeaderBar = styled.div`
  ${tw`pt-6 md:pt-4 lg:pt-12 px-6 md:px-6 lg:px-20 flex items-center justify-between w-full`};
`
const LogoAnchor = styled(Link)`
  ${tw`no-underline text-inherit shadow-none hover:bg-transparent`};
`
const LogoImage = styled(GatsbyImage)`
  ${tw`h-10 w-10 sm:h-8 sm:w-8 md:h-12 md:w-12 lg:h-12 lg:w-12 m-0`};
`
const Navbar = styled.nav`
  ${tw``};
`
const NavList = styled.ul`
  ${tw`text-xs m-0 uppercase tracking-wide md:text-sm flex items-center`};
`
const NavItem = styled.li`
  ${tw`ml-4 md:ml-6 my-auto inline-block no-underline`};
`
const NavItemLink = styled(Link)`
  ${tw`no-underline font-bold text-black dark:text-white hover:text-blue shadow-none`};
`

const Header = () => (
  <StaticQuery
    query={graphql`
      query HeaderImage {
        logo: file(relativePath: { eq: "company-logo.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              width: 72
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    `}
    render={({ logo }) => (
      <Container>
        <Headroom>
          <HeaderBar className="unpad-if-pinned">
            <LogoAnchor to="/">
              <LogoImage
                alt="Logo"
                title="Logo"
                image={logo.childImageSharp.gatsbyImageData}
              />
            </LogoAnchor>

            <Navbar>
              <NavList>
                <NavItem>
                  <NavItemLink to="/portfolio/">Projects</NavItemLink>
                </NavItem>
                <NavItem>
                  <NavItemLink to="/articles/">Articles</NavItemLink>
                </NavItem>
                <NavItem>
                  <NavItemLink to="/about/">About</NavItemLink>
                </NavItem>
                <NavItem>
                  <ThemeToggle />
                </NavItem>
              </NavList>
            </Navbar>
          </HeaderBar>
        </Headroom>
      </Container>
    )}
  />
)

export default Header
