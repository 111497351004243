import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { Sun, Moon } from 'react-feather'
import tw, { styled } from 'twin.macro'

const ToggleButton = styled.button`
  width: min-content;
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    ${tw`text-black dark:text-white w-[1rem] h-[1rem] md:(w-[1.25rem] h-[1.25rem])`}
  }
`

function ThemeToggle() {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <ToggleButton
          aria-label={`${
            theme === 'dark' ? 'Toggle light theme' : 'Toggle dark theme'
          }`}
          onClick={() => toggleTheme(theme === 'dark' ? 'light' : 'dark')}
        >
          {theme === 'dark' ? <Sun /> : <Moon />}
          
        </ToggleButton>
      )}
    </ThemeToggler>
  )
}

export default ThemeToggle
