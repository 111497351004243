import tw, { css, theme } from 'twin.macro'

export const LightBackground = theme`colors.white`
export const DarkBackground = theme`colors.night-sky`

const baseStyles = css`
  .light {
    --bg-primary: ${LightBackground};
    --text-primary: ${theme`colors.black`};
    --text-dark: ${theme`colors.white`};
  }
  .dark {
    --bg-primary: ${DarkBackground};
    --text-primary: ${theme`colors.white`};
  }
  body {
    background-color: var(--bg-primary);
    color: var(--text-primary);
  }
`

export default baseStyles
