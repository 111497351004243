import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import tw, { styled } from 'twin.macro'
import { StaticQuery, graphql } from 'gatsby'
import { Global } from '@emotion/react'

import BaseStyles from './base-styles'
import Header from './header'
import Footer from './footer'
import PreFooter from './prefooter'

import '../sass/layout.sass'

const Content = styled.main`
  ${tw`px-6 w-full lg:w-4/5 xl:w-2/3 mx-auto dark:bg-night-sky`};

  a {
    ${tw`hover:(bg-blue-lightest dark:bg-grey-darker) 
    dark:!text-white dark:!shadow-linkDark 
    hover:(text-[#3273dc] dark:!bg-grey-dark)`};
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet title={data.site.siteMetadata.title}>
          <html lang="en" />
        </Helmet>
        <Global styles={BaseStyles} />
        <Header siteTitle={data.site.siteMetadata.title} />
        <Content>{children}</Content>
        <PreFooter />
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
