import React from 'react'
import tw, { styled } from 'twin.macro'

const Container = styled.footer`
  ${tw`px-6 md:px-0 py-8 md:py-8 bg-white dark:bg-night-sky text-center`};

  a {
    ${tw`text-black dark:text-white hover:(dark:bg-grey-darker)`};
  }
`
const Inner = styled.div`
  ${tw`opacity-75 my-2`};
`

const Footer = () => (
  <Container>
    <Inner>
      This site was created using the{' '}
      <a href="https://jamstack.org/">Jamstack</a>.
    </Inner>
    <Inner>
      All articles © James Does Digital {new Date().getFullYear()}. All
      rights reserved.
    </Inner>
  </Container>
)

export default Footer
