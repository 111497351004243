import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import tw, { styled } from 'twin.macro'
import logo from '../images/company-logo.png'

const Container = styled.section`
  ${tw`px-6 md:px-0 pt-12 pb-8 md:py-16 bg-grey-lightest dark:(bg-night-sky border-t-[1px] border-b-[1px]
    border-t-grey-darker border-b-grey-darker border-l-0 border-r-0 border-solid) text-center 
    lg:text-left mt-12 md:mt-24`};

  a {
    ${tw`text-black dark:text-white hover:(dark:bg-grey-darker)`};
  }
`
const Inner = styled.div`
  ${tw`flex justify-between w-full lg:w-4/5 xl:w-3/4 mx-auto flex-wrap md:flex-nowrap`};
`
const Row = styled.div`
  ${tw`w-full xl:w-1/4 lg:mx-8 mb-6`};
`
const ItemTitle = styled.div`
  ${tw`font-bold mb-1`};
`
const Item = styled.div`
  ${tw``};
`
const LogoLink = styled(Link)`
  ${tw`no-underline text-inherit shadow-none hover:bg-transparent`};
  &:hover {
    background: none !important;
    color: transparent !important;
    box-shadow: none !important;
  }
`
const LogoImage = styled.img`
  ${tw`h-16 w-16 my-0 mx-auto`};
`

export const PreFooter = () => (
  <StaticQuery
    query={graphql`
      query PrefooterQuery {
        latestBlogPosts: allContentfulBlogPost(
          sort: { fields: [publishDate], order: DESC }
          limit: 3
        ) {
          edges {
            node {
              id
              slug
              title
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Container id="prefooter">
          <Inner>
            <Row>
              <LogoLink to="/">
                <LogoImage src={logo} alt="Logo" />
              </LogoLink>
            </Row>
            <Row>
              <ItemTitle>James Does Digital</ItemTitle>
              <Item>Software Development</Item>
              <Item>Cloud Computing</Item>
              <Item>
                <a
                  alt="Send me an email"
                  title="Send me an email"
                  href="mailto:hello@jamesdoesdigital.com"
                >
                  hello@jamesdoesdigital.com
                </a>
              </Item>
            </Row>
            <Row>
              <ItemTitle>Current Address</ItemTitle>
              <Item>Edinburgh</Item>
              <Item>Scotland</Item>
              <Item>UK</Item>
            </Row>
            <Row>
              <ItemTitle>Socials</ItemTitle>
              <Item>
                <a
                  alt="GitHub"
                  title="GitHub"
                  href="https://github.com/jamesdoesdigital"
                >
                  GitHub
                </a>
              </Item>
              <Item>
                <a
                  alt="Twitter"
                  title="Twitter"
                  href="https://twitter.com/jmsdoesdigital"
                >
                  Twitter
                </a>
              </Item>
              <Item>
                <a
                  alt="Instagram"
                  title="Instagram"
                  href="https://instagram.com/jamesdoesdigital"
                >
                  Instagram
                </a>
              </Item>
            </Row>
          </Inner>
        </Container>
      )
    }}
  />
)

export default PreFooter
